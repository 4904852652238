import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "styled-media-query"
import Layout from "../components/layout"
import SEO from "../components/seo"

// const Content = styled.div`
//   margin: 0 auto;
//   max-width: 860px;
//   padding: 1.45rem 1.0875rem;
// `

// const MarkedHeader = styled.h1`
//   display: inline;
//   border-radius: 1em 0 1em 0;
//   background-image: linear-gradient(
//     -100deg,
//     rgba(255, 250, 150, 0.15),
//     rgba(255, 250, 150, 0.8) 100%,
//     rgba(255, 250, 150, 0.25)
//   );
// `

const HeaderDate = styled.h3`
  font-family: "Nunito";
  font-weight: 600;
  color: #00b4a0;
  display: flex;
  flex: 1 1 100%;
  font-size: 1.35rem;

  ${media.lessThan("medium")`
        font-size: 1.25rem; 
    `}
`

// STYLE THE TAGS INSIDE THE MARKDOWN HERE
const MarkdownContent = styled.div`
  width: 100%;
  a {
    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      rgba(255, 250, 150, 0.8),
      rgba(255, 250, 150, 0.8)
    );
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    &:hover {
      background-size: 100% 88%;
    }
  }
`

const FeaturedImageWrapper = styled.div`
  position: relative;
  height: 75vh;
  overflow: hidden;
  display: flex;

  ${media.greaterThan("medium")`
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        height: 100vh;
    `}
`

const FeaturedImage = styled(Img)`
  min-height: 100vh;

  ${media.lessThan("medium")`
        min-height: 75vh;
    `}
`

const Content = styled.div`
  margin: 0 auto;
  padding: 10rem 0;
  max-width: 90%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.lessThan("medium")`
      padding: 5rem 0;
      max-width: 90%;
  `}
`

const PostWrapper = styled.div`
  display: flex;
  flex: 1 1 72%;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -30rem;

  ${media.lessThan("medium")`
        margin-top: -20rem;
    `}
`

const PostContainer = styled.div`
    display: flex; 
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-start;
    background #FFF;
    padding: 5rem 5rem 0;
    flex-flow: row wrap;
    max-width: 72%;

    ${media.lessThan("large")`
        max-width: 90%;
    `}

    ${media.lessThan("medium")`
        max-width: 100%;
        padding: 2rem 2rem 0;
    `}
`

const MarkedHeader = styled.h1`
  font-size: 4rem;
  color: #141414;
  display: flex;
  font-weight: 100;
  flex: 1 1 100%;
  text-align: left;

  ${media.lessThan("medium")`
        font-size: 2.5rem; 
        text-align: center;
        width: 90%;
    `}
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 72%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: auto;

  ${media.lessThan("medium")`
        flex: 1 1 100%;
        align-items: center;
    `}
`

const CaseStudyDescription = styled.p`
  font-size: 1.8rem;
  font-weight: 100;
  color: #222;
  line-height: 1.75;
  flex: 1 1 100%;
  margin-bottom: 0;
`

export default (props) => {
  console.log(props)
  const post = props.data.markdownRemark
  const image = post.frontmatter?.featuredImage.childImageSharp.fluid
  const cardImage = post.frontmatter?.featuredImage.childImageSharp.resize

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={cardImage}
      />
      <FeaturedImageWrapper>
        {image && (
          <FeaturedImage
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              zIndex: -1,
            }}
            fluid={image}
          />
        )}
      </FeaturedImageWrapper>

      <Content>
        <PostWrapper>
          <PostContainer>
            <MarkedHeader>{post.frontmatter.title}</MarkedHeader>
            <HeaderDate>
              {post.frontmatter.date} - {post.fields.readingTime.text}
            </HeaderDate>
            <ContentContainer>
              <MarkdownContent
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </ContentContainer>
          </PostContainer>
        </PostWrapper>
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
        slug
        description
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 2000
              quality: 70
              cropFocus: ENTROPY
              duotone: { highlight: "#00b4a0", shadow: "#141414" }
            ) {
              ...GatsbyImageSharpFluid
            }
            resize(
              width: 1500
              quality: 70
              duotone: { highlight: "#00b4a0", shadow: "#141414" }
            ) {
              width
              src
              height
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
